//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-return-assign */
import {rzPreloader, rzContainer} from 'razlet-ui';
import PopularDirections from '~/components/popular-directions';

export default {
  name: 'index',
  layout: 'index',
  components: {
    rzPreloader,
    rzContainer,
    PopularDirections,
  },
  data() {
    return {
      isMounted: false,
      isAffiliatorDefined: false,
      isAffiliatorLoaded: false,
      isScheduleDefined: false,
      isScheduleLoaded: false,
      isCalendarDefined: false,
      isCalendarLoaded: false,
      isAffiliatorReady: false,
      isSlotMounted: false,
      direction: '',
    };
  },
  computed: {
    language() {
      return this.$local ? this.$local : 'ru';
    },
  },
  watch: {
    isSlotMounted: {
      handler(val) {
        if (val) this.addPopularDirections();
      },
    },
  },
  mounted() {
    this.isMounted = true;
    window.customElements.whenDefined('razlet-affiliator').then(() => {
      this.isAffiliatorDefined = true;
      this.isAffiliatorLoaded = true;
    });
    window.customElements.whenDefined('razlet-schedule').then(() => {
      this.isScheduleDefined = true;
      this.isScheduleLoaded = true;
    });
    window.customElements.whenDefined('razlet-calendar').then(() => {
      this.isCalendarDefined = true;
      this.isCalendarLoaded = true;
    });
  },
  methods: {
    ready() {
      this.isAffiliatorReady = true;
      this.isSlotMounted = true;
    },
    selectDirection(direction) {
      this.direction = `${direction.from.code}0000${direction.to.code}1000es`;
    },
    addPopularDirections() {
      const parentEl = document.getElementById('homeSlot');
      if (parentEl) {
        const directionsEl = this.$refs.popularDirections.$el;
        directionsEl.className = 'popular-directions';
        parentEl.appendChild(directionsEl);
        parentEl.className = 'visible';
      }
    },
  },
  head () {
    return {
      script: [
        { 
          skip: !this.isMounted || this.isAffiliatorDefined,
          hid: 'avia',
          vmid: 'avia', 
          type: 'text/javascript',
          src: `${process.env.affURL}/krasavia/avia/main.js`,
          async: true,
          callback: () => { this.isAffiliatorLoaded = true; },
        },
        { 
          skip: !this.isMounted || this.isScheduleDefined,
          hid: 'schedule',
          vmid: 'schedule', 
          type: 'text/javascript',
          src: `${process.env.affURL}/krasavia/schedule/main.js`,
          async: true,
          callback: () => { this.isScheduleLoaded = true; },
        },
        { 
          skip: !this.isMounted || this.isCalendarDefined,
          hid: 'calendar',
          vmid: 'calendar', 
          type: 'text/javascript',
          src: `${process.env.affURL}/krasavia/calendar/main.js`,
          async: true,
          callback: () => { this.isCalendarLoaded = true; },
        },
      ],
    };
  },
};
